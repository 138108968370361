<template>
    <b-modal ref="twoFaModal"
        :title="$t(tKey + 'header')"
        header-bg-variant="custom"
        header-text-variant="light"
        centered
        size="lg"
        hide-footer
    >
        <div class="d-flex">
            <div>
                <div>
                    <h6 class="mb-0 pl-3" v-html="$t(tKey + 'qrCode')" />
                    <b-img v-if="qr" :src="qr" width="300" />
                    <b-skeleton-img v-else width="300" height="300" />
                </div>
                <div>
                    <h6 class="mb-2 pl-3" v-html="$t(tKey + 'manualCode')" />
                    <h5 v-if="manualCode" class="mb-0 pl-3" v-html="manualCode" />
                </div>
            </div>
            <div class="px-3">
                <span v-html="$t(tKey + 'info')" />
                <div class="mt-4">
                    <b v-html="$t(tKey + 'codeLabel')" />
                    <PincodeInput
                        v-model="code"
                        placeholder="0"
                        :length="6"
                    />
                </div>
                <b-alert variant="danger" :show="isWrong">
                    <span v-html="$t(tKey + 'wrong')" />
                </b-alert>
            </div>
        </div>
    </b-modal>
</template>

<script>
import PincodeInput from 'vue-pincode-input'
import QrcodeVue from 'qrcode.vue'
export default {
    name: 'EnableAuthenticatorModal',
    components: {
        QrcodeVue,
        PincodeInput,
    },
    props: {
        tKey: { type: String, required: true },
    },
    data() {
        return {
            qr: null,
            manualCode: null,
            code: '',
            isWrong: false,
        }
    },
    watch: {
        code(val) {
            if(val.length === 6) this.$emit('checkAuthCode', val)
        },
    },
    methods: {
        show(data) {
            this.qr = data.QrCode
            this.manualCode = data.ManualCode
            this.$refs.twoFaModal.show()
        },
        hide() {
            this.$refs['twoFaModal'].hide()
        },
        showError() {
            this.isWrong = true
        },
    },
}
</script>

<style lang="scss">
input.vue-pincode-input {
  box-shadow: none;
  border: 1px solid theme-color-level('custom', -3);
}
</style>